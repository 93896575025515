.logo-container {
    overflow: hidden;
}

.home-page {
    overflow-y: auto;
    overflow-x: hidden;
    .text-zone {
        position: absolute;
        left: 10%;
        top: 50%;
        transform: translateY(-50%);
        width: 100%;
        max-height: 90%;
        height: 80%; // Adjust the height of the bounding box
        
        
    }

    .project-zone {
        /* Customize the scrollbar */
        scrollbar-width: thin;
        scrollbar-color: #ffd700 #1b1b1be3;
        
        &::-webkit-scrollbar {
            width: 8px;
        }
        
        &::-webkit-scrollbar-track {
            background-color: #1b1b1be3;
        }
        
        &::-webkit-scrollbar-thumb {
            background-color: #ffd700;
            border-radius: 4px;
        }
        
        position: absolute;
        left: 40%;
        background-color: #1b1b1b00;
        padding-right: 1%;
        padding-left: 1%;
        top: 0%;
        border-radius: 10px;
        transform: translateY(-50%);
        min-height: 40%;
        max-height: 100%;
        max-width: 40%;
        overflow-y: auto;
        overflow-x: hidden;
        z-index: 1;
        opacity: 0;
        animation: bounceIn 2s 0s forwards;
    }

    @media (max-width: 1084px) {

        overflow-y: auto;
        overflow-x: hidden;
        .project-zone {
            position: relative;
            max-width: 80%;
            top: 15%;
            left: 0%;
        }
    }

    @media (max-width: 750px) {

        .project-zone {
            max-width: 100% !important;
        }
    }


    .languages{
        position: relative;
        top : 15%;
        transform: translateY(-50%);
        width: 100%;
        max-height: 12%;
        height: 80%; // Adjust the height of the bounding box
        background-color: #ec737300;
        padding-right: 1%;
        padding-left: 1%;
        border-radius: 10px;
        overflow: hidden;
        z-index: 1;

        i {
            font-size: 50px;
            color: #6e6e6e81;
            margin-right: 20px;

            &:hover {
                color: #ffd90075;
            }
        }

    }


    h1 {
        color: #fff;
        font-size: 53px;
        margin: 0;
        font-family: 'Coolvetica';
        font-weight: 400;

        &::before{
            content: '<h1>';
            font-family: 'La Belle Aurore';
            color: #ffd700;
            font-size: 18px;
            position:absolute;
            margin-top: -40px;
            left: 15px;
            opacity: 0.6;
        }

        &::after{
            content: '</h1>';
            font-family: 'La Belle Aurore';
            color: #ffd700;
            font-size: 18px;
            position:absolute;
            margin-top: 18px;
            margin-left: 20px;
            animation: fadeIn ls 1.7s backwards;
            opacity: 0.6;
        }

        img {
            width: 46px;
            margin-left: 20px;
            opacity: 0;
            height: auto;
            animation: rotateIn 1s linear both;
            animation-delay: 1.5s;
        }
    }

    h2 {
        color: #8d8d8d;
        margin-top: 20px;
        font-weight: 400;
        font-size: 11px;
        font-family: sans-serif;
        letter-spacing: 3px;
        animation: fadeIn 1s 1.8s backwards;
    }

    .flat-button {
        color: #ffd700;
        font-size: 13px;
        font-weight: 400;  
        letter-spacing: 4px;
        font-family: sans-serif;
        text-decoration: none;
        padding: 10px 10px;
        border: 1px solid #ffd700;
        margin-top: 25px;
        float: left;
        animation: fadeIn 1s 1.8s backwards;
        white-space: nowrap;

        &:hover{
            background: #ffd700;
            color: #333;

        }

    }
}