.project {
    height: 100%;
    width: 100%;
    border-radius: 10px;
}

.project-element {
    height: 100%;
    width: 100%;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;

    

    h2 {
        position: absolute;
        font-family: "Helvetica Neue";
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        margin: auto;
        width: 100%;
        height: 100%;
        text-align: center;
        font-size: 3rem;
        color: #fff;
        z-index: 1;
        border-radius: 20px;
        padding: 1rem;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }

    @media (max-width: 750px) {

        h2 {
            font-size: 20px;
        }
    }

    video {
        position: absolute;
        top: 0;
        right: auto;
        bottom: 15px;
        margin: auto;
        width: 100%;
        transform: translateX(-50%);
        left: 50%;
        opacity: 0;
        z-index: 1;
        border-radius: 20px;
        
        &:hover {
            animation: fadeInVideo 0.5s linear both;
            + img {
                opacity: 0;
            }
        }

        @keyframes fadeInVideo {
            0% {
                opacity: 0;
            }
            100% {
                opacity: 1;
                
            }
        }

    }
    img {
        opacity: 0.5;
        transition: opacity 0.5s;
        width: 100%;
        height: 40%;
        object-fit: absolute;
        margin-bottom: 10px;
        border-radius: 20px;

        &.fade-in {
            animation: fadeIn 1s linear both;
            animation-delay: 1.5s;
        }

    }
    
    
}
    
