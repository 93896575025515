.logo-container {
    z-index: -1;
    width: 100%;
    height: auto;
    position: absolute;
    top: 0;
    right: 15%;
    bottom: 0;
    left: auto;
    margin: auto;
    opacity: 1;

    svg {
        width: 100%;
        height: auto;
        bottom: 0;
        transform: rotateZ(30deg) !important;
        opacity: 0.2;

    }

    .solid-logo {
        position: absolute;
        top: auto;
        right: auto;
        bottom: auto;
        left: 0;
        margin: auto;
        width: 100%;
        opacity: 0;
        transform: rotateZ(30deg);
        z-index: 0;
    }
}

.svg-container {
    stroke: #ffd700;
    stroke-width: 0.1px;
}