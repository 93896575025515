.contact-form {
    width: 100%;
    margin-top: 20px;

    ul {
        padding: 0;
        margin: 0;

        li {
            padding: 0;
            margin: 0;
            list-style: none;
            margin-bottom: 10px;
            overflow: hidden;
            display: block;
            position: relative;
            opacity: 0;
            animation: fadeInUp 2s 2s;
            animation-fill-mode: forwards;
            clear: both;
        }

        li.half {
            width: 49%;
            margin-left: 2%;
            float: left;
            clear: none;

            &:first-child{
                margin-left: 0;
            }

        }
    }

    input[type='text'],
    input[type='email'] {
        width: 100%;
        border: 0;
        background: #115173;
        height: 50px;
        font-size: 16px;
        color: #fff;
        padding: 0 20px;
        box-sizing: border-box;

    }

    textarea {
        width: 100%;
        border: 0;
        background: #115173;
        height: 50px;
        font-size: 16px;
        color: #fff;
        padding: 20px;
        min-height: 150px;
        box-sizing: border-box;
    }

    .flat-button {
        color: #ffd700;
        background: 0 0;
        font: 11px;
        letter-spacing: 3px;
        text-decoration: none;
        padding: 8px 10px;
        border: 1px solid #ffd700;
        float: right;
        border-radius: 4px;

        &:hover{
            background: #ffd700;
            color: #333;
    
        }
    }


}

.self {
        position: absolute;
        left: 55%;
        background-color: #efd0d000;
        display: flex;
        justify-content: center;
        align-items: center;
        padding-right: 1%;
        padding-left: 1%;
        top: 50%;
        border-radius: 10px;
        transform: translateY(-50%);
        min-height: 50%;
        max-height: 90%;
        max-width: 30%;
        overflow: hidden;
        z-index: 1;

        img{
            width: 96%;
            height: auto;
            border-radius: 10px;
            animation: fadeIn 1s 1.8s backwards;
        }
}



@media (max-width: 750px) {
    
    .contact-page{
        position: relative;
    }
    .contact-form {
        position: relative;
    
    }
    .self {
        
        position: relative;
        max-width: 70%;
        background-color: rgba(255, 217, 0, 0);
        left: 15%;
        top: 900px;

    }
}