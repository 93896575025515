// Left navigation bar
.nav-bar {
    background: #181818;
    width: 60px;
    height: 100%;
    position: absolute;
    top: 0;
    z-index: 3;
    min-height: 500px;

    .logo {
        display: block;
        padding: 8px 0;

        img {
            display: block;
            margin: 8px auto;
            width: 32px;
            height: auto;
    
            &.sub-logo {
                width: 50px;
            }
        }
    }

    nav {
        display: block;
        text-align: center;
        position: absolute;
        height: 210px;
        top: 50%;
        margin-top: -120px;
        width: 100%;

        a{
            font-size: 22px;
            color: #4d4d4e;
            display: block;
            line-height: 51px;
            height: 51px;
            position: relative;
            text-decoration: none;

            i{
                transition: all 0.3s ease;
            }

            &:hover{
                svg {
                    opacity:1;
                    color:  #ffd90088;
                }

            }

        }


        a.active {
            svg{
                color: #ffd700;
            }
        }
    }

    ul {
        position: absolute;
        bottom: 20px;
        width: 100%;
        display: block;
        padding: 0;
        list-style: none;
        text-align: center;
        margin: 0;

        li {
            a {
                padding: 7px 0;
                display: block;
                font-size: 15px;
                line-height: 16px;

                &:hover svg {
                    color: #ffd700;
                }
            }
        }
    }

}

@media (max-width: 750px) {
    

    .nav-bar {
        background-color: #18181834;
    }
    
    .icon {
        opacity: 0.5;
    }

}